import React, { useState, useEffect } from 'react';
import CSVReader from 'react-csv-reader';
import { Line } from "react-chartjs-2";
// import { Chart, registerables } from 'chart.js';
import { findPutIV, findCallIV, calcCallDelta, calcPutDelta, evaluatePositions_freeTrade, calcCallPrem, calcPutPrem } from './func';
import { OPresult, evalTmp_FreeTrade } from './graphOptions';

function FreeSim() {
   //get date and time
   let newTime = new Date();
   let d = newTime.toLocaleDateString('sv-SE');//today for option input
   let t = newTime.getHours().toString().padStart(2, '0') + ":" + newTime.getMinutes().toString().padStart(2, '0');
   //set rate and U/L Price
   const rate = 1;
   const ULP = 30000;
   //initialize positions and simulation condition state
   const nSims = 5
   let posiData = {
      ForO: "", strk: "", CorP: "", lastDay: "", LorS: "", lot: "", entryPrice: "", delta: "", IV: "", entryDay: "", entryTime: "", ulPrice: ""
   }
   let posiTmp = new Array(nSims).fill([]);
   const [positions, setPositions] = useState(posiTmp);
   let simCondition = {
      today: d, time: t, ulPr: ULP, IVchg: 0
   }
   let simConditonTmp = new Array(nSims).fill(simCondition);
   const [simConditions, setSimConditions] = useState(simConditonTmp);
   //graph state
   const [evalData, setEvalData] = useState(evalTmp_FreeTrade);
   const [graphOP, setGO] = useState(OPresult);

   //set labels(x axis) of graph data at beginning
   useEffect(() => {
      const nLables = 81; const nDist = 125; //-5000~5000
      const xMin = -1 * nDist * Math.floor(nLables / 2)
      let labels = new Array(nLables);
      for (let i = 0; i < nLables; i++) {
         labels[i] = xMin + nDist * i
      }
      let simData = JSON.parse(JSON.stringify(evalTmp_FreeTrade));
      simData.labels = labels;
      setEvalData(simData);
   }, [])

   //function to add position
   const handleAddPosi = (e, i, ForO) => {

      e.preventDefault();
      const eTarget = e.target

      //minus delta for selling
      let adj = 1;
      if (eTarget.LorS.value === "S") { adj = -1; }

      //check input
      if (ForO === 'O') {
         //OPTION
         if (!ForO || !eTarget.strk.value || !eTarget.CorP.value || !eTarget.lastDay.value || !eTarget.LorS.value || !eTarget.lot.value || !eTarget.entryPrice.value || !eTarget.entryDay.value || !eTarget.entryTime.value || !eTarget.ulPrice.value) {
            alert("Input all fields");
            return -1;
         }
         posiData.ForO = ForO;
         posiData.strk = eTarget.strk.value;
         posiData.CorP = eTarget.CorP.value;
         posiData.lastDay = eTarget.lastDay.value;
         posiData.LorS = eTarget.LorS.value;
         posiData.lot = eTarget.lot.value;
         posiData.entryPrice = eTarget.entryPrice.value;
         posiData.entryDay = eTarget.entryDay.value;
         posiData.entryTime = eTarget.entryTime.value;
         posiData.ulPrice = eTarget.ulPrice.value;
         //IV and Delta
         if (eTarget.CorP.value === "C") {
            posiData.IV = findCallIV(posiData.entryPrice, posiData.ulPrice, posiData.strk, posiData.entryDay, posiData.lastDay, rate, posiData.entryTime);
            posiData.delta = calcCallDelta(posiData.ulPrice, posiData.strk, posiData.entryDay, posiData.lastDay, posiData.IV, rate, posiData.entryTime);
            posiData.delta = Math.round(posiData.delta * 100) / 100 * adj * (posiData.lot / 100);
         } else if (eTarget.CorP.value === "P") {
            posiData.IV = findPutIV(posiData.entryPrice, posiData.ulPrice, posiData.strk, posiData.entryDay, posiData.lastDay, rate, posiData.entryTime);
            posiData.delta = calcPutDelta(posiData.ulPrice, posiData.strk, posiData.entryDay, posiData.lastDay, posiData.IV, rate, posiData.entryTime);
            posiData.delta = Math.round(posiData.delta * 100) / 100 * adj * (posiData.lot / 100);
         }

      } else {
         //FUTURE
         if (!ForO || !eTarget.LorS.value || !eTarget.lot.value || !eTarget.entryPrice.value) {
            alert("Input all fields");
            return -1;
         }
         posiData.strk = "-"; posiData.CorP = "-"; posiData.lastDay = "-"; posiData.entryDay = "-";
         posiData.ForO = ForO;
         posiData.LorS = eTarget.LorS.value;
         posiData.lot = eTarget.lot.value;
         posiData.entryPrice = eTarget.entryPrice.value;
         posiData.delta = 0.01 * posiData.lot * adj;
      }

      //add position
      setPositions(positions => positions.map((position, index) => index === i ? [...position, posiData] : position))
   }
   //function to delete position
   const deleteFn = (i, index) => {
      const newArray = [...positions];
      newArray[i].splice(index, 1)
      setPositions(newArray);
   }

   //function to copy previous position
   const copyPosition = (i) => {
      //const newArray = [...positions];
      const newArray = JSON.parse(JSON.stringify(positions));//deep copy
      newArray[i] = positions[i - 1]
      setPositions(newArray);
   }

   //function to add simulation graph
   const handleAddSim = (e, i) => {
      e.preventDefault();
      if (positions[i].length === 0) {
         alert("No Positions to Simulate.");
         return;
      }
      let PosiGraph = JSON.parse(JSON.stringify(evalData));
      PosiGraph.datasets[i].label = simConditions[i].today.slice(-5) + "(" + simConditions[i].time;
      PosiGraph.datasets[i].data = evaluatePositions_freeTrade(positions[i], simConditions[i], rate)
      setEvalData(PosiGraph);
   }

   //copy prev simulation condition
   const copySimCondition = (e, i) => {
      e.preventDefault();
      const newArray = [...simConditions];
      newArray[i] = simConditions[i - 1]
      setSimConditions(newArray);
   }

   //graph range change function
   const handleGraphChange = (e) => {
      e.preventDefault();
      let gr = Number(e.target.value);
      setGO({
         ...graphOP,
         scales: {
            ...graphOP.scales,
            x: { ...graphOP.scales.x, min: gr * -1, max: gr }
         }
      }
      )
   }
   //upload position csv
   const uploadCSV = (data, fileInfo, i) => {
      let posi = [...data];
      const id = Number(data[0].day) - 1;

      let check = window.confirm(`<Day${id + 1}>のポジションデータをアップロードしようとしています。\r\n<Day${id + 1}>のDate、U/L Priceなどのパラメータは入力しましたか？`)
      if (!check) {
         document.getElementById('myCSV').value = '';
         return;
      }

      posi.forEach((p) => {
         //delete parameter of sim condition
         delete p.space; delete p.day; delete p.today; delete p.time; delete p.ulPr; delete p.IVchg;
         //delta
         //minus delta for selling
         let adj = 1;
         if (p.LorS === "S") { adj = -1; }
         if (p.CorP === "C") {
            p.IV = findCallIV(p.entryPrice, p.ulPrice, p.strk, p.entryDay, p.lastDay, rate, p.entryTime);
            p.delta = calcCallDelta(simConditions[id].ulPr, p.strk, simConditions[id].today, p.lastDay, p.IV + simConditions[id].IVchg, rate, simConditions[id].time);
            p.delta = Math.round(p.delta * 100) / 100 * adj * (p.lot / 100);
         } else if (p.CorP === "P") {
            p.IV = findPutIV(p.entryPrice, p.ulPrice, p.strk, p.entryDay, p.lastDay, rate, p.entryTime);
            p.delta = calcPutDelta(simConditions[id].ulPr, p.strk, simConditions[id].today, p.lastDay, p.IV + simConditions[id].IVchg, rate, simConditions[id].time);
            p.delta = Math.round(p.delta * 100) / 100 * adj * (p.lot / 100);
         } else if (p.ForO === 'F') {
            p.delta = 0.01 * p.lot * adj;
         }
      })

      setPositions(positions => positions.map((position, index) => index === id ? [...position, ...posi] : position))

      //reset input
      document.getElementById('myCSV').value = '';
   };

   return (
      <div className='freetrade-wrapper'>
         <div>
            <div className='csv-input'>
               <CSVReader inputId="myCSV"
                  parserOptions={{ header: true }}
                  onFileLoaded={(data, fileInfo) => uploadCSV(data, fileInfo)}
                  label="Input Positons with CSV"
                  inputStyle={{ display: 'none' }}
               />
            </div>
            {simConditions.map((simCondition, i) =>
               <div key={i} className='freetrade-panel'>
                  <div>
                     <div className='freetrade-h1'>Day{i + 1}</div>
                     <div className='freetrade-inputfield'>
                        {/*sim conditions*/}
                        <div className='sim-condition' >
                           <form onSubmit={e => handleAddSim(e, i)}>
                              <div className="dateinput">
                                 <label>Date: </label>
                                 <input type="date" defaultValue={simConditions[i].today} value={simConditions[i].today}
                                    onChange={e => {
                                       setSimConditions((prevState) => {
                                          const result = JSON.parse(JSON.stringify(prevState));
                                          result[i].today = e.target.value;
                                          return result;
                                       })
                                    }}
                                 />
                                 <label>Time: </label>
                                 <input className="dateinput" type="time" defaultValue={simConditions[i].time} value={simConditions[i].time}
                                    onChange={e => {
                                       setSimConditions((prevState) => {
                                          const result = JSON.parse(JSON.stringify(prevState));
                                          result[i].time = e.target.value;
                                          return result;
                                       })
                                    }}
                                 />
                                 {i > 0 ? <div className='copyposi simcon'><button onClick={(e) => copySimCondition(e, i)}>Copy From Day{i}</button></div> : <></>}

                              </div><br />
                              <label>U/L Price: </label>
                              <input type="number" defaultValue={simConditions[i].ulPr} step="5" value={simConditions[i].ulPr}
                                 onChange={e => {
                                    setSimConditions((prevState) => {
                                       const result = JSON.parse(JSON.stringify(prevState));
                                       result[i].ulPr = e.target.value;
                                       return result;
                                    })
                                 }}
                              />
                              <label>IV change(%): </label>
                              <input type="number" defaultValue={simConditions[i].IVchg} value={simConditions[i].IVchg}
                                 onChange={e => {
                                    setSimConditions((prevState) => {
                                       const result = JSON.parse(JSON.stringify(prevState));
                                       result[i].IVchg = Number(e.target.value);
                                       return result;
                                    })
                                 }}
                              />
                              <br />
                              <button type="submit">Add Sim{i + 1}</button>
                           </form>
                        </div>
                        <br />
                        {/*add positions*/}
                        <div className='sim-position'>
                           {/* add option */}
                           <label className='ForO'>Option </label>
                           <br />
                           <form onSubmit={e => handleAddPosi(e, i, 'O')} >
                              <label>Strike: </label>
                              <input id={`forOP${i}0`} type="number" name="strk" min="1" />
                              <label>Call/Put: </label>
                              <select id={`forOP${i}1`} name="CorP" defaultValue={"C"}>
                                 <option value="C">Call</option>
                                 <option value="P">Put</option>
                              </select>
                              <div className="dateinput">
                                 <label>Last Trading Day: </label>
                                 <input className="dateinput" id={`forOP${i}2`} type="date" name="lastDay" />
                              </div>
                              <br />
                              <label>Buy/Sell: </label>
                              <select name="LorS" defaultValue={"L"}>
                                 <option value="L">Buy</option>
                                 <option value="S">Sell</option>
                              </select>
                              <label>Price: </label>
                              <input type="number" name="entryPrice" min="1" />
                              <label>Lot:</label>
                              <input type="number" name="lot" step="100" defaultValue="100" /><br />
                              <div className='dateinput'>
                                 <label>Entry Day: </label>
                                 <input type="date" name="entryDay" id={`forOP${i}3`} defaultValue={d} />
                                 <label>Time: </label>
                                 <input type="time" name="entryTime" defaultValue={t} id={`forOP${i}4`} />
                              </div>
                              <label>U/L Price: </label>
                              <input type="number" name="ulPrice" step="5" defaultValue={ULP} id={`forOP${i}5`} /><br />
                              <button type="submit">add Option</button>
                           </form>
                           <br />
                           {/* add future */}
                           <label className='ForO'>Future</label>
                           <br />
                           <form onSubmit={e => handleAddPosi(e, i, 'F')} >
                              <label>Buy/Sell: </label>
                              <select name="LorS" defaultValue={"L"}>
                                 <option value="L">Buy</option>
                                 <option value="S">Sell</option>
                              </select>
                              <label>Price: </label>
                              <input type="number" name="entryPrice" min="1" />
                              <label>Lot:</label>
                              <input type="number" name="lot" step="10" defaultValue="10" /><br />
                              <button type="submit">add Future</button>
                           </form>
                        </div>
                        <br />
                        {/* position list */}
                        <div className='posilist-wrapper'>
                           <div className='h2'>Position List</div>
                           {i > 0 ? <div className='copyposi'><button onClick={() => copyPosition(i)}>Copy From Day{i}</button></div> : <></>}

                           <table className='kodai-table'>
                              <thead>
                                 <tr>
                                    <th>F/O</th><th>Strike</th><th>Call/Put</th><th>Last Day</th><th>Long/Short</th><th>Lot</th><th>Entry Price</th><th>Delta</th><th>Entry Date</th>
                                 </tr>
                              </thead>

                              {positions[i].length > 0 ? <>
                                 <tbody>
                                    {positions[i].map((position, index) => (
                                       <tr key={index}>
                                          {Object.values(position).map((val, j) => {
                                             // show month and day for date
                                             if (j == 3)
                                                return <td> {val.slice(-5)}</td>
                                             else if (j == 9)
                                                return <td> {val.slice(-5)}</td>
                                             // not showing IV, etc.
                                             else if (j >= 8)
                                                return
                                             else
                                                return <td> {val}</td>
                                          })}
                                          <button onClick={() => deleteFn(i, index)}>-</button>
                                       </tr>
                                    ))
                                    }
                                 </tbody>
                              </> :
                                 <>
                                    {/* <div>No Position...</div> */}
                                 </>}
                           </table>
                        </div>
                     </div>


                  </div>
               </div>
            )
            }
         </div >
         {/* graph data */}
         {evalData ?
            <div className='freetrade-graph'>
               <div className='range-selector'>
                  <label>Graph Range: </label>
                  <select defaultValue={1500} onChange={(e) => handleGraphChange(e)}>
                     <option key="1" >500</option>
                     <option key="2" >1000</option>
                     <option key="3" >1500</option>
                     <option key="4" >2000</option>
                     <option key="5" >3000</option>
                     <option key="6" >5000</option>
                  </select>
               </div>
               <div className='bar-chart'>
                  <Line data={evalData} options={graphOP} />
               </div>
            </div> : <></>
         }
      </div>
   )
}

export default FreeSim

